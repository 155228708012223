<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea v">
			<div class="inner">
				<h2 class="txtL v3">프로젝트 목록</h2>
				<a @click="moveBack()" class="btnBack">이전 페이지</a>
				<!-- <div class="rightArea">
					<a @click="moveBack()" class="btn btnWhite ss">관리빌딩으로 이동</a>
				</div> -->
			</div>
		</div>
		<!--// titArea -->
		<!-- contents -->
		<div class="contents">
			<!-- listTop -->
			<div class="listTop v2">
				<div class="right">
					<a class="btnAddObject" @click="onMove()" v-if="buildingOwner == loginUserId">프로젝트 등록</a>
				</div>
			</div>
			<!--// listTop -->
			<!-- colTable -->
			<div class="colTable">
				<table>
					<template v-if="project.total > 0">
						<caption>
							프로젝트 명, 등록된 메모 항목으로 구성된 프로젝트 리스트 표
						</caption>
						<colgroup>
							<col style="width:60px" />
							<col style="width:auto" />
							<col style="width:200px" class="onlyPC" />
							<col style="width:150px" />
							<col style="width:100px" />
						</colgroup>
						<thead>
							<tr>
								<th scope="col"></th>
								<th scope="col">프로젝트 명</th>
								<th scope="col" class="onlyPC">프로젝트 기간</th>
								<th scope="col">등록된 메모</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody id="projectList">
							<tr v-for="(project, index) in projectList" :key="index">
								<td>
									<span v-if="project.dateOrder == 1" class="label v1"></span>
									<span v-if="project.dateOrder == 2" class="label v2"></span>
								</td>
								<td>
									<a @click="onProjectMemo(project.id, project.name)">{{ project.name }}</a>
								</td>
								<td class="onlyPC">
									{{ project.beginDate | dateFormatYear }} ~ {{ project.endDate | dateFormatYear }}
								</td>
								<td>{{ project.projectCount }}</td>
								<td>
									<a class="btn btnWhite ss" @click="onUpdate(project.id)" v-if="buildingOwner == loginUserId">수정</a>
								</td>
							</tr>
						</tbody>
					</template>

					<template v-else>
						<caption>
							프로젝트 명, 등록된 메모 항목으로 구성된 프로젝트 리스트 표
						</caption>
						<colgroup>
							<col style="width:60px" />
							<col style="width:auto" />
							<col style="width:30%" />
							<col style="width:100px" />
						</colgroup>
						<thead>
							<tr>
								<th scope="col"></th>
								<th scope="col">프로젝트 명</th>
								<th scope="col">등록된 메모</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody id="projectList">
							<tr>
								<td colspan="4"><p>등록된 프로젝트가 없습니다.</p></td>
							</tr>
						</tbody>
					</template>
				</table>
			</div>
			<!--// colTable -->
		</div>
		<!--// contents -->
	</div>
	<!--// container -->
</template>
<script>
import project from '../../api/project';
import building from '@/api/building';

import { showSpinner, hideSpinner } from '@/utils/cmm';
import { dateFormatYear } from '@/utils/cmm';
import store from '@/store';

export default {
	//	components: {  },
	filters: {
		dateFormatYear,
	},
	created() {},
	mounted() {
		this.buildingId = this.$route.params.buildingId;
		if (!this.buildingId) {
			this.$route.push({ name: 'buildingList' });
			return;
		}
		this.reload();
	},
	beforeDestroy() {},
	data() {
		return {
			buildingId: null,
			projectList: [],
			project: {
				offset: 0,
				limit: 10,
				total: '',
			},
			buildingOwner: null,
			loginUserId: null,
		};
	},
	methods: {
		async onGetProject() {
			let params = {
				buildingId: Number(this.$route.params.buildingId),
			};
			try {
				// data (projectList , total) 가져오기.
				let res = await project.get(params);
				this.project.total = res.total;
				// total ( 등록된 projectList)가 없으면 미수행
				if (this.project.total) {
					res.data.forEach(p => this.projectList.push(p));
				}
			} catch (ex) {
				await this.$alert(`${ex.message}`);
			}
		},
		async reload() {
			try {
				showSpinner();
				let res = await building.getOne(this.$route.params.buildingId);

				// 빌딩 소유자?등록자? 정보 가져오기
				this.buildingOwner = res.owner;
				this.loginUserId = store.state.auth.id;
				this.onGetProject();
			} catch (ex) {
				await this.$alert(`건물 상세조회 에러가 발생했습니다.\r\n(${ex.message})`);
				return this.$router.go(-1);
			} finally {
				hideSpinner();
			}
		},
		onMove() {
			this.$router.push({ name: 'projectRegist', params: { buildingId: this.buildingId } });
		},
		onUpdate(id) {
			this.$router.push({ name: 'projectUpdate', params: { buildingId: this.buildingId, id: id } });
		},
		moveBack() {
			this.$router.push({ name: 'buildingDetail', params: { id: this.buildingId } });
		},
		onProjectMemo(id, name) {
			this.$router.push({ name: 'projectMemoList', params: { buildingId: this.buildingId, id: id, name: name } });
		},
	},
};
</script>
<style scoped>
@media (max-width: 767px) {
	.onlyPC {
		display: none;
	}
}
</style>
